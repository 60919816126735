.logo-holder {
    width: 100%;
    text-align: center;
    height: 25vh;
    position: relative;
    cursor: pointer;
}

.login-actualization-strip {
    background-color: #f0f0f0;
    width: 100%;
    top: 75%;
    left: 50%;
    position: absolute;
    transform: translate(-50%, -50%);
}

.login-strip {
    background-color: #dee5eb;
    width: 100%;
    height: 50%;
    top: 50%;
    left: 50%;
    position: absolute;
    transform: translate(-50%, -50%);
}

.login-wrapper {
    min-width: 300px;
    top: 50%;
    left: 50%;
    position: absolute;
    transform: translate(-50%, -50%);
}

.login-input {
    width: 100%;
    max-width: 400px;
    border-radius: 4px;
    border-style: solid;
    border-width: 1px;
    padding: 8px 14px;
    border-color: #8e8e8e;
    cursor: text;
    min-height: 40px;
    box-sizing: border-box;
    margin-bottom: 10px;
}

.login-submit {
    border: 0px;
    height: 35px;
    min-width: 90px;
    display: inline-block;
    border-radius: 4px;
    -webkit-font-smoothing: antialiased;
    padding: 0px 15px;
    /* font-size: 15px; */
    font-weight: bold;
    outline: none;
    box-shadow: none;
    cursor: pointer;
    margin-top: 10px;
    vertical-align: middle;
    text-align: center;
    background-color: #013b60;
    color: rgb(255, 255, 255);
    float: right;
}

.login-submit:disabled {
    border: 0px;
    height: 35px;
    min-width: 90px;
    display: inline-block;
    border-radius: 4px;
    -webkit-font-smoothing: antialiased;
    padding: 0px 15px;
    /* font-size: 15px; */
    font-weight: bold;
    outline: none;
    box-shadow: none;
    cursor: pointer;
    margin-top: 10px;
    vertical-align: middle;
    text-align: center;
    background-color: gray;
    color: rgb(255, 255, 255);
    float: right;
}